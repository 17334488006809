import algoliasearch from 'algoliasearch';

export function HelpSearch() {
    const searchClient = algoliasearch('GVE73Q4AE9', '750bf3ef992f8c690c53b2b30533996a');
    const helpSearchIndexName = 'help_center_search';

    return {    
        query_hits: [],
        query: '',
        searchIndex: null,
        resultsHeightAdjust: 0,
        
        get autofocus() {
            return this.$el.dataset.autofocus;
        },

        get indexSettings() {
            return {
                /* 
                 * I tried using this to limit the highlighted results
                 * but then quickly realized this is for specifically 
                 * limiting the result content, not the highlight content
                attributesToSnippet: [
                    'content:5'
                ], */
            };
        },

        get searchQuerySettings() {
            return  {
                hitsPerPage: 8,
                highlightPreTag: '<strong>',
                highlightPostTag: '</strong>'
            };
        },

        get hits() {
            if(this.query <= 3) {
                return [];
            }

            return this.query_hits;
        },

        async runSearch(inputQuery) {
            return this.searchIndex.search(inputQuery, this.searchQuerySettings).then( ({ hits }) => { 
                this.query_hits = hits;
                console.log(this.query_hits)
            } );
        },

        async processQuery(input_value) {
            if (!input_value || input_value.length < 4) { 
                this.runSearch('').catch(error => console.log(error));
                return; 
            }

            this.runSearch(input_value)
                .catch(error => console.log(error));
        },

        /* get resultsHeightAdjust() {
            return this.$refs.inputField.offsetHeight;
        }, */

        // resultsHeightAdjust: 0,

        async init() {
            this.searchIndex = searchClient.initIndex(helpSearchIndexName);            
            this.searchIndex.setSettings(this.indexSettings);

            this.$watch("query", value => this.processQuery(value));

            if(this.autofocus) {
                this.$nextTick(this.$refs.inputField.focus());
            }

            /* this.$nextTick().finally(() => {
                this.resultsHeightAdjust = this.$refs.inputField.offsetHeight;
            }) */
        },

        /**
         * For arrow key up to cycle through results
         * @param {int} index index of search results
         */
        focusPreviousResult(index){
            if( index == 0 || index == undefined){
                index = this.hits.length;
            }
            var t = document.querySelector("#help-search-result-" + (index - 1));
            if (t){ return t.focus() }
        },

        /**
         * For arrow key down to cycle through results
         * @param {int} index index of search results
         */
        focusNextResult(index){
            if( index + 1 > this.hits.length - 1 || index == undefined){
                index = -1;
            }
            var t = document.querySelector("#help-search-result-" + (index + 1));
            if (t){ return t.focus() }
        },
    }
}
export function HelpSearchSpanish() {
    const searchClient = algoliasearch('GVE73Q4AE9', '750bf3ef992f8c690c53b2b30533996a');
    const helpSearchIndexName = 'help_center_search_spanish';

    return {    
        query_hits: [],
        query: '',
        searchIndex: null,
        resultsHeightAdjust: 0,
        
        get autofocus() {
            return this.$el.dataset.autofocus;
        },

        get indexSettings() {
            return {
                /* 
                 * I tried using this to limit the highlighted results
                 * but then quickly realized this is for specifically 
                 * limiting the result content, not the highlight content
                attributesToSnippet: [
                    'content:5'
                ], */
            };
        },

        get searchQuerySettings() {
            return  {
                hitsPerPage: 8,
                highlightPreTag: '<strong>',
                highlightPostTag: '</strong>'
            };
        },

        get hits() {
            if(this.query <= 3) {
                return [];
            }

            return this.query_hits;
        },

        async runSearch(inputQuery) {
            return this.searchIndex.search(inputQuery, this.searchQuerySettings).then( ({ hits }) => { 
                this.query_hits = hits;
                console.log(this.query_hits)
            } );
        },

        async processQuery(input_value) {
            if (!input_value || input_value.length < 4) { 
                this.runSearch('').catch(error => console.log(error));
                return; 
            }

            this.runSearch(input_value)
                .catch(error => console.log(error));
        },

        /* get resultsHeightAdjust() {
            return this.$refs.inputField.offsetHeight;
        }, */

        // resultsHeightAdjust: 0,

        async init() {
            this.searchIndex = searchClient.initIndex(helpSearchIndexName);            
            this.searchIndex.setSettings(this.indexSettings);

            this.$watch("query", value => this.processQuery(value));

            if(this.autofocus) {
                this.$nextTick(this.$refs.inputField.focus());
            }

            /* this.$nextTick().finally(() => {
                this.resultsHeightAdjust = this.$refs.inputField.offsetHeight;
            }) */
        },

        /**
         * For arrow key up to cycle through results
         * @param {int} index index of search results
         */
        focusPreviousResult(index){
            if( index == 0 || index == undefined){
                index = this.hits.length;
            }
            var t = document.querySelector("#help-search-result-" + (index - 1));
            if (t){ return t.focus() }
        },

        /**
         * For arrow key down to cycle through results
         * @param {int} index index of search results
         */
        focusNextResult(index){
            if( index + 1 > this.hits.length - 1 || index == undefined){
                index = -1;
            }
            var t = document.querySelector("#help-search-result-" + (index + 1));
            if (t){ return t.focus() }
        },
    }
}