import algoliasearch from 'algoliasearch';

export function Search(){
    const searchClient = algoliasearch('GVE73Q4AE9', '750bf3ef992f8c690c53b2b30533996a');
    const searchIndexName = 'global_site_search';
    const suggestionsIndexName = 'global_site_search_query_suggestions';

    
    return {
        hits: [],
        query: '',
        // searchOpen: false,
        popular_limit: 4,
        searchIndex: null,
        suggestionsIndex: null,
        suggestionHits: [],
        isMobile: window.matchMedia('(max-width: 1024px)').matches,

        get indexSettings() {
            return {
                /* 
                 * I tried using this to limit the highlighted results
                 * but then quickly realized this is for specifically 
                 * limiting the result content, not the highlight content
                attributesToSnippet: [
                    'content:5'
                ], */
                attributesToHighlight: [
                    'title',
                    'content',
                    'helpArticle_category',
                    'articleBody',
                    'slug',
                    'jobDescription',
                ]
            };
        },

        get searchQuerySettings() {
            return  {
                hitsPerPage: 8,
                highlightPreTag: '<strong>',
                highlightPostTag: '</strong>'
            };
        },

        get resultsTitle() {
            if(this.query != '' && this.query.length > 3) {
                return `Results Containing <strong>'${this.query}'</strong>`;
            }

            if(this.isMobile) {
                return '';
            }
            
            return 'Popular Searches';
        },

        get filtered_hits() {
            // Hijacking this.hits so that we can manipulate the results without
            // having to refactor how hits gets assigned.
            /* if(this.hits.length == 0) {
                return [];
            } */
            if( this.isMobile && this.query.length <= 3 ) {
                return [];
            }

            // Swap in the suggestions if the user has typed less than 3 characters
            if( !this.isMobile && this.query.length <= 3 ) {
                return this.suggestionHits;
            }

            return this.hits;
        },

        async runSuggestions() {
            return this.suggestionsIndex.search('', this.searchQuerySettings).then( ({ hits }) => {
                this.suggestionHits = hits;
            });
        },

        async runSearch(inputQuery) {
            return this.searchIndex.search(inputQuery, this.searchQuerySettings).then( ({ hits }) => { 
                this.hits = hits;
            } );
        },

        init(){
            this.$watch("query", (value) =>{

                if (!value || value.length < 4) { 
                    this.runSearch('').catch(error => console.log(error));
                    return; 
                }

                this.runSearch(value)
                    .catch(error => console.log(error));
            });

            this.searchIndex = searchClient.initIndex(searchIndexName);            
            this.searchIndex.setSettings(this.indexSettings);
            
            this.suggestionsIndex = searchClient.initIndex(suggestionsIndexName);

            this.runSuggestions()
                .then(() => this.runSearch(''))
                .catch(error => console.log(error));

            /* this.$watch('searchOpen', (value)=>{
                //Focus the search input when it gets expanded
                if(value){ this.$refs.searchInput.focus(); }
                //empty search input when closed
                if(!value){ this.query = '' }
            }); */

            // this.$watch('navOpen', (value)=>{
            //     document.body.classList.add('h-screen', 'overflow-hidden');
            // });
        },

        highlightResultText(hitResultData) {
            // console.log(hitResultData)
            var textResult = '';

            for (const [key, value] of Object.entries(hitResultData)) {
                if(typeof value.matchedWords == 'object' && value.matchedWords.length) {
                    textResult = value.value;
                }
            }

            // return the string if it has a <p> tag in it, wrap it otherwise
            return (textResult.includes('<p>')) ? textResult : `<p>${textResult}</p>`;
        },

        /**
         * For arrow key up to cycle through results
         * @param {int} index index of search results
         */
        focusPreviousResult(index){
            if( index == 0 || index == undefined){
                index = this.hits.length;
            }
            var t = document.querySelector("#site-search-result-" + (index - 1));
            if (t){ return t.focus() }
        },

        /**
         * For arrow key down to cycle through results
         * @param {int} index index of search results
         */
        focusNextResult(index){
            if( index + 1 > this.hits.length - 1 || index == undefined){
                index = -1;
            }
            var t = document.querySelector("#site-search-result-" + (index + 1));
            if (t){ return t.focus() }
        },
        
        /**
         * Resets it all
         */
        clear(){
            // this.searchOpen = false;
            this.query = '';
            // this.hits = [];

            this.runSearch(this.query).catch(error => console.log(error));
        },

        /**
         * slash key hit while on page opens and focuses search
         * @param {$event} e event object
         */
        handleSlashKey(e){
            /* "/" === e.key && (e.preventDefault(),
            this.searchOpen = true,
            this.$refs.searchInput.focus()) */
        }
    }
}

export function SearchSpanish(){
    const searchClient = algoliasearch('GVE73Q4AE9', '750bf3ef992f8c690c53b2b30533996a');
    const searchIndexName = 'global_site_search_spanish';
    const suggestionsIndexName = 'global_site_search_query_suggestions';

    
    return {
        hits: [],
        query: '',
        // searchOpen: false,
        popular_limit: 4,
        searchIndex: null,
        suggestionsIndex: null,
        suggestionHits: [],
        isMobile: window.matchMedia('(max-width: 1024px)').matches,

        get indexSettings() {
            return {
                /* 
                 * I tried using this to limit the highlighted results
                 * but then quickly realized this is for specifically 
                 * limiting the result content, not the highlight content
                attributesToSnippet: [
                    'content:5'
                ], */
                attributesToHighlight: [
                    'title',
                    'content',
                    'helpArticle_category',
                    'articleBody',
                    'slug',
                    'jobDescription',
                ]
            };
        },

        get searchQuerySettings() {
            return  {
                hitsPerPage: 8,
                highlightPreTag: '<strong>',
                highlightPostTag: '</strong>'
            };
        },

        get resultsTitle() {
            if(this.query != '' && this.query.length > 3) {
                return `Results Containing <strong>'${this.query}'</strong>`;
            }

            if(this.isMobile) {
                return '';
            }
            
            return 'Popular Searches';
        },

        get filtered_hits() {
            // Hijacking this.hits so that we can manipulate the results without
            // having to refactor how hits gets assigned.
            /* if(this.hits.length == 0) {
                return [];
            } */
            if( this.isMobile && this.query.length <= 3 ) {
                return [];
            }

            // Swap in the suggestions if the user has typed less than 3 characters
            if( !this.isMobile && this.query.length <= 3 ) {
                return this.suggestionHits;
            }

            return this.hits;
        },

        async runSuggestions() {
            return this.suggestionsIndex.search('', this.searchQuerySettings).then( ({ hits }) => {
                this.suggestionHits = hits;
            });
        },

        async runSearch(inputQuery) {
            return this.searchIndex.search(inputQuery, this.searchQuerySettings).then( ({ hits }) => { 
                this.hits = hits;
            } );
        },

        init(){
            this.$watch("query", (value) =>{

                if (!value || value.length < 4) { 
                    this.runSearch('').catch(error => console.log(error));
                    return; 
                }

                this.runSearch(value)
                    .catch(error => console.log(error));
            });

            this.searchIndex = searchClient.initIndex(searchIndexName);            
            this.searchIndex.setSettings(this.indexSettings);
            
            this.suggestionsIndex = searchClient.initIndex(suggestionsIndexName);

            this.runSuggestions()
                .then(() => this.runSearch(''))
                .catch(error => console.log(error));

            /* this.$watch('searchOpen', (value)=>{
                //Focus the search input when it gets expanded
                if(value){ this.$refs.searchInput.focus(); }
                //empty search input when closed
                if(!value){ this.query = '' }
            }); */

            // this.$watch('navOpen', (value)=>{
            //     document.body.classList.add('h-screen', 'overflow-hidden');
            // });
        },

        highlightResultText(hitResultData) {
            // console.log(hitResultData)
            var textResult = '';

            for (const [key, value] of Object.entries(hitResultData)) {
                if(typeof value.matchedWords == 'object' && value.matchedWords.length) {
                    textResult = value.value;
                }
            }

            // return the string if it has a <p> tag in it, wrap it otherwise
            return (textResult.includes('<p>')) ? textResult : `<p>${textResult}</p>`;
        },

        /**
         * For arrow key up to cycle through results
         * @param {int} index index of search results
         */
        focusPreviousResult(index){
            if( index == 0 || index == undefined){
                index = this.hits.length;
            }
            var t = document.querySelector("#site-search-result-" + (index - 1));
            if (t){ return t.focus() }
        },

        /**
         * For arrow key down to cycle through results
         * @param {int} index index of search results
         */
        focusNextResult(index){
            if( index + 1 > this.hits.length - 1 || index == undefined){
                index = -1;
            }
            var t = document.querySelector("#site-search-result-" + (index + 1));
            if (t){ return t.focus() }
        },
        
        /**
         * Resets it all
         */
        clear(){
            // this.searchOpen = false;
            this.query = '';
            // this.hits = [];

            this.runSearch(this.query).catch(error => console.log(error));
        },

        /**
         * slash key hit while on page opens and focuses search
         * @param {$event} e event object
         */
        handleSlashKey(e){
            /* "/" === e.key && (e.preventDefault(),
            this.searchOpen = true,
            this.$refs.searchInput.focus()) */
        }
    }
}