export default function Nav(){
    return {
        dropdownHeight: '400px',
        dropdownWidth: '400px',
        activeDropdown: false,
        activeDropdownPos: 0,
        hamburger: false,
        noDrop:{
            ['@mouseenter']($event){
                if(!window.matchMedia('(max-width: 768px)').matches){
                    //console.log('click', $event);
                    this.close()
                }
            },
        },
        navBtn: {
            ['@click.prevent']($event){
            //    console.log('click', $event);
               this.open($event.target.getAttribute('href'), $event.clientX)
            },
            ['@mouseenter']($event){
                if(!window.matchMedia('(max-width: 768px)').matches){
                    //console.log('click', $event);
                    this.open($event.target.getAttribute('href'), $event)
                }
                
            },
            ['@click']($event){
                console.log('click', $event);
                this.open($event.target.getAttribute('href'), $event)
            },
            [':class'](){
                return this.activeDropdown == this.$el.getAttribute('href').substring(1) && 'is-open';
            }
        },
        open(name, {target}){
            console.log(target);
            const dropdown = document.querySelector(name);
            // console.log(dropdown,dropdown.clientHeight,dropdown.clientWidth);
            this.dropdownHeight = dropdown.clientHeight + 'px';
            this.dropdownWidth = dropdown.clientWidth + 'px';
            this.dropdownLeft = target.getBoundingClientRect().x + target.clientWidth/2;
            this.activeDropdown = name.substring(1);
            // dropdown.removeAttribute('hidden');
            this.$nextTick(()=>{
                this.activeDropdownPos = target.getBoundingClientRect();
            })
            
        },
        close(){
            this.activeDropdown = false;
        },
        dropdownWrap:{
            ['x-bind:class'](){
                return {'is-hidden' : !this.activeDropdown};
            }
        },
        dropdownNav:{
            ['x-bind:style'](){
                return `
                    --dropdownHeight: ${this.dropdownHeight};
                    --dropdownWidth: ${this.dropdownWidth}; 
                    --dropdownLeft: ${this.dropdownLeft}px;
                    --dropdownTop: ${this.activeDropdownPos.top}px;
                `
            },
            ['@mouseleave'](){
                this.close()
            }
        },

        hamburgerBtn:{
            ['@click'](){
                this.hamburger = !this.hamburger;
            }
        }

    }
}


